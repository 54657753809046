import { Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import taurageLogoImage from 'assets/taurage_logo.svg';
import telsiaiLogoImage from 'assets/telsiai_header.png';
import jonavaLogoImage from 'assets/jonava.jpeg';
import plungeLogoImage from 'assets/plunge_logo.png';

const taurageLogo = <Box p='1rem'><Link to='/'><Image src={taurageLogoImage} /></Link></Box>;
const telsiaiLogo = <Box><Link to='/'><Image src={telsiaiLogoImage} /></Link></Box>;
const jonavaLogo = <Box><Link to='/'><Image src={jonavaLogoImage} /></Link></Box>;
const plungeLogo = <Box p='1rem'><Link to='/'><Image h="80px" src={plungeLogoImage} /></Link></Box>;

let config = {};

switch (window.location.hostname) {
  case 'marsrutai.taurage.lt':
    config = {
      baseUrl: 'https://tracker.dutrys.com/api/v2/bucket/67',
      logo: taurageLogo,
      mapCenter: [55.250398, 22.291265],
    };
    break;

  case 'marsrutai.telsiuap.lt':
  case 'telsiai.dutrys.lt':
    config = {
      baseUrl: 'https://tracker.dutrys.com/api/v2/bucket/78',
      logo: telsiaiLogo,
      mapCenter: [55.984734, 22.251782],
    };
    break;
    
  case 'jonava.dutrys.lt':
    config = {
      baseUrl: 'https://tracker.dutrys.com/api/v2/bucket/51',
      logo: jonavaLogo,
      mapCenter: [55.0788587,24.2637209],
    };
    break;
  
  case 'plunge.dutrys.lt':
  case 'marsrutai.plungesap.lt':
    config = {
      baseUrl: 'https://tracker.dutrys.com/api/v2/bucket/91',
      logo: plungeLogo,
      mapCenter: [55.912915,21.830539],
    };
    break;

  default:
    config = {
      baseUrl: 'http://localhost:3033/api/v2/bucket/91',
      logo: plungeLogo,
      mapCenter: [55.912915,21.830539],
    };
    break;
}

export default config;
