import { useCallback, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMapEvent } from 'react-leaflet';

import { useBoundsStopsQuery } from "services/api/queries";
import { stores } from "store/stores";
import { useSelectedStore } from "store/useSelectedStore";
import * as selectors from "store/selectors";
import { getStopMarker } from "utils/getStopMarker";

export function StopsInBounds() {
  const location = useLocation();
  const { userLocation, map } = useSelectedStore(stores.map, [selectors.userLocation, selectors.map]);
  const [bounds, setBounds] = useState(null);
  const { data: stops } = useBoundsStopsQuery(bounds);
  const isUserLocated = useMemo(() => userLocation?.lat && userLocation?.lng, [userLocation?.lat, userLocation?.lng]);
  const updateMapBounds = useCallback(() => {
    if (location.pathname === '/' || location.pathname.includes('/stops') || isUserLocated) {
      if (map.getZoom() >= 15) {
        const {_southWest, _northEast} = map.getBounds();
        const data = [
          _southWest.lat, _southWest.lng,
          _northEast.lat, _northEast.lng
        ];
        setBounds(data.join(','));
      } else {
        setBounds(null);
      }
    } else {
      setBounds(null);
    }
  }, [map, location, isUserLocated])
  
  useMapEvent('moveend', updateMapBounds);

  const markers = useMemo(() => stops?.map(getStopMarker), [stops]);

  return markers;
}