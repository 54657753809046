import { useEffect } from 'react';
import { decode } from '@googlemaps/polyline-codec';

import * as selectors from 'store/selectors';
import { useSelectedStore } from 'store/useSelectedStore';
import { stores } from 'store/stores'

export function useMapEffects() {
  useBoundsChange();
  useClearTrack();
  useSetTrack();
  usePanToTrackAndSelectedStop();
  usePanToUserLocation();
}

function useClearTrack() {
  const { directionId } = useSelectedStore(stores.route, [
    selectors.directionId
  ]);
  const { clearTrack } = useSelectedStore(stores.map, [
    selectors.clearTrack,
  ]);

  useEffect(() => {
    if (!directionId) {
      clearTrack();
    }
  }, [clearTrack, directionId]);
}

function usePanToUserLocation() {
  const { map, defaultZoom, userLocation } = useSelectedStore(
    stores.map,
    [
      selectors.map,
      selectors.defaultZoom,
      selectors.userLocation,
    ]
  );

  useEffect(() => {
    if (map) {
      if (userLocation && !userLocation.error) {
        map.setView([userLocation.lat, userLocation.lng], defaultZoom + 3);
      }
    }
  }, [map, userLocation, defaultZoom]);
}

function usePanToTrackAndSelectedStop() {
  const { map, center, track, defaultZoom } = useSelectedStore(
    stores.map,
    [
      selectors.map,
      selectors.center,
      selectors.defaultZoom,
      selectors.track
    ]
  );
  const { selectedStop } = useSelectedStore(
    stores.route,
    [
      selectors.selectedStop
    ]
  );

  useEffect(() => {
    if (map) {
      if (selectedStop) {
        const { lat, lng } = selectedStop;
        map?.setView([lat, lng], defaultZoom + 3);
      } else if (track.length) {
        map.fitBounds(track);
      }
    }
  }, [selectedStop, map, center, defaultZoom, track]);
}

function useSetTrack() {
  const { directionId, track, directionStops } = useSelectedStore(
    stores.route,
    [
      selectors.directionId,
      selectors.track,
      selectors.directionStops,
    ]
  );
  const { setTrackStops, setTrack } = useSelectedStore(
    stores.map,
    [
      selectors.setTrack,
      selectors.setTrackStops,
    ]
  );
  
  useEffect(() => {
    if (directionId) {
      const decodedTrack = decode(track);
      const routeStops = directionStops?.map(s => ({lat: s.lat, lng: s.lng, name: s.name}));
      setTrackStops(routeStops);
      setTrack(decodedTrack);
    }
  }, [setTrack, setTrackStops, directionId, track, directionStops]);
}

function useBoundsChange() {
  const { map, track, center, defaultZoom } = useSelectedStore(stores.map, [
    selectors.map,
    selectors.track,
    selectors.center,
    selectors.defaultZoom,
  ]);

  const { selectedStop } = useSelectedStore(
    stores.route,
    [
      selectors.selectedStop
    ]
  );

  useEffect(() => {
    if (map && !selectedStop && !track.length) {
      map.setView(center, defaultZoom);
    }
  }, [track, map, center, defaultZoom, selectedStop]);
}
