import create from 'zustand';

const defaultStore = {
  routeId: null,
  routeNumber: null,
  directionEnd: null,
  directionName: null,
  directionId: null,
  directionStops: null,
  selectedStop: null,
  track: null,
};

export const useRouteDirectionStore = create(set => ({
    ...defaultStore,
    setRouteDirection: (data) => set({
      routeId: data.id,
      routeNumber: data.number,
      directionName: data.directionName,
      directionId: data.directionId,
      directionStops: data.stops,
      directionEnd: data.stops[data.stops.length - 1].name,
      track: data.track,
    }),
    setSelectedStop: (stop) => set({selectedStop: stop}),
    clearRouteDirection: () => set({...defaultStore}),
    clearSelectedStop: () => set({selectedStop: null}),
  })
);
