import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom"

import { BreadcrumbLink } from './BreadcrumbLink';

import { useSelectedStore } from 'store/useSelectedStore';
import { stores } from 'store/stores';
import * as selectors from 'store/selectors';

export function Breadcrumbs() {
  const { routeNumber, selectedStop } = useSelectedStore(
    stores.route,
    [selectors.routeNumber, selectors.selectedStop]
  );
  const { t } = useTranslation();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState();

  useEffect(() => {
    const subpaths = location.pathname.split(/\/(?=[a-zA-Z])/);
    const crumbs = subpaths.reduce((result, i, ind, arr) => {
      if (i === '') {
        result.push(<BreadcrumbLink key={`${ind}.0`} to='/' value={t('home')} />);
        return result;
      }

      const [entry, id] = i.split(/\/(?=\d+)/);
      const entryPath = [...arr.slice(0, ind), entry];

      if (entry === 'routes') {
        result.push(<BreadcrumbLink key={`${id}.0`} to={entryPath.join('/')} value={t('routes')} />);
        if (id) {
          result.push(<BreadcrumbLink key={`${id}.1`} to={entryPath.concat(id).join('/')} value={routeNumber} />);
        }
      }

      if (entry === 'stop') {
        if (id) {
          result.push(<BreadcrumbLink key={`${id}.0`} to={entryPath.concat(id).join('/')} value={selectedStop?.name} />);
        }
      }

      if (entry === 'stops') {
        result.push(<BreadcrumbLink key={`${id}.0`} to={entryPath.join('/')} value={t('stops')} />);
        // if (id) {
        //   result.push(<BreadcrumbLink key={`${id}.1`} to={entryPath.concat(id).join('/')} value={id} />);
        // }
      }

      return result
    }, []);
    
    setBreadcrumbs(crumbs);
  }, [location.pathname, t, routeNumber, selectedStop]);
  
  if (breadcrumbs?.length) {
    return (
      <Flex p='1rem' wrap='wrap'>
        {breadcrumbs}
      </Flex>
    );
  }
  
  return null;
}