import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Icon, ListItem, List, Heading, Flex, VStack, Select, Tooltip } from '@chakra-ui/react';
import { MdSwapHoriz } from 'react-icons/md';

import { BusNumber } from './BusNumber';
import { Loader } from './Loader';

import { useSelectedStore } from 'store/useSelectedStore';
import { stores } from 'store/stores';
import * as selectors from 'store/selectors';

import { useRouteQuery } from '../services/api/queries';

export function BusRoute() {
  const { t } = useTranslation();
  const params = useParams();
  const {
    routeNumber,
    directionId,
    directionName,
    directionStops,
    setRouteDirection,
    clearSelectedStop
  } = useSelectedStore(stores.route, [
    selectors.routeNumber,
    selectors.directionId,
    selectors.directionName,
    selectors.directionStops,
    selectors.setRouteDirection,
    selectors.clearSelectedStop
  ]);
  const { data, isLoading } = useRouteQuery(params.routeId);

  useEffect(() => {
    clearSelectedStop()
  }, [clearSelectedStop]);

  useEffect(() => {
    if (data && !directionId) {
      setRouteDirection(data[0]);
    }
  }, [data, setRouteDirection, directionId]);

  const handleDirectionChange = (event) => {
    if (event.target.value) {
      const direction = data.find(i => i.directionId === parseInt(event.target.value));
      setRouteDirection(direction);
    } else {
      const direction = data.find(i => i.directionId !== directionId);
      setRouteDirection(direction);
    }
  }

  return (
    <>
      <VStack maxH='100vh' w='100%' overflow='scroll'>
        {
          isLoading ? <Loader size='lg' /> :
          <>
            <Flex w='100%' alignItems='center' p='1rem'>
              <BusNumber number={routeNumber} />
              {data.length > 2 ?
                <Select defaultValue={directionId} w='85%' onChange={handleDirectionChange} ml='1rem'>
                  {data.map(i => <option value={i.directionId} key={i.directionId}>{i.directionName}</option>)}
                </Select>
                :
                <Flex alignItems='center' w='85%' ml='1rem'>
                  <Heading size='sm'>{directionName}</Heading>
                  {data.length > 1 &&
                    <Tooltip label={t('route.directionChange')}>
                      <Box ml='auto' d='flex'>
                        <Icon
                          as={MdSwapHoriz}
                          w={6}
                          h={6}
                          cursor='pointer'
                          onClick={handleDirectionChange}
                        />
                      </Box>
                    </Tooltip>
                  }
                </Flex>
              }
            </Flex>
            <Heading as='h4' p='1rem' bgColor='gray.50' w='100%' m='1rem 0 0.5rem' size='sm'>{t('route.stops')}</Heading>
            <Box w='100%' overflowY='scroll' >
              <List w='100%' m={0}>
                {directionStops?.map((stop, ind) => {
                  return (
                    <Link key={ind} to={`direction/${directionId}/stop/${stop.id}`}>
                      <ListItem
                        cursor='pointer'
                        _hover={{
                          bgColor: 'gray.100'
                        }}
                        p='0.75rem 1rem'
                        borderTop='1px'
                        borderBottom='1px'
                        borderColor='gray.100'
                        listStyleType='none'
                      >
                        {stop.name}
                      </ListItem>
                    </Link>
                  );
                })}
              </List>
            </Box>
          </>
        }
      </VStack>
    </>
  );
}
