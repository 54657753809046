import L from 'leaflet';
import { Marker, Popup } from "react-leaflet";
import { Flex, Text } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { BusNumber } from './BusNumber';

import busStop_m from 'assets/busstop_m.png';

export function BusStopMarker(props) {
  const { t } = useTranslation();
  const busStopIcon = new L.Icon({
    iconUrl: busStop_m,
    iconAnchor: [15, 41],
  });

  return (
    <Marker
      icon={busStopIcon}
      position={{lat: props.stop.lat, lng: props.stop.lng}}
    >
      <Popup>
        <Link to={`stops/${props.stop.id}`} style={{color: 'initial !important'}}>
          <Flex width='300px' direction='column'>
            <Text fontSize='md' fontWeight={500} m='0px !important'>{props.stop.name}</Text>
            <Text
              m='0px !important'
              fontSize='sm'
              color='gray.600'
            >
              {t('route.directionEnd', { directionEnd: props.stop.towards })}
            </Text>
            <Flex wrap='wrap'>
              {props.stop.routes?.map((route, ind) => <BusNumber number={route.number} key={`${ind}.${route?.number}`} mr={1} mt={1} />)}
            </Flex>
          </Flex>
        </Link>
      </Popup>
    </Marker>
  )
}
