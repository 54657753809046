import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Flex, Text } from '@chakra-ui/react';
import { IoLocationOutline } from 'react-icons/io5';
import { MdMyLocation } from 'react-icons/md';

import { Loader } from './Loader';
import { SearchQueryInput } from './SeachQueryInput';
import { StopListItem } from './StopListItem';
import { MenuGroup } from './MenuGroup';

import { useSearchStopsQuery } from 'services/api/queries';
import { useSelectedStore } from 'store/useSelectedStore';
import { stores } from 'store/stores';
import * as selectors from 'store/selectors';

export function Stops() {
  const { t } = useTranslation();
  const { userLocation, setUserLocation } = useSelectedStore(stores.map, [selectors.userLocation, selectors.setUserLocation]);
  const [query, setQuery] = useState(null);
  const [queryDraft, setQueryDraft] = useState('');

  const { data, isLoading, isError } = useSearchStopsQuery(query, !!query);

  useEffect(() => {
    const queryTimeout = setTimeout(() => setQuery(queryDraft), 300);
    return () => clearTimeout(queryTimeout);
  }, [queryDraft]);

  const handleChange = event => setQueryDraft(event.target.value);
  const handleClearInput = () => setQueryDraft('');

  // return { queryData, queryDraft, handleChange, handleClearInput };
  // const { queryData, queryDraft, handleChange, handleClearInput } = useSearchAndQuery(useSearchStopsQuery);
  // const { data, isLoading, isError } = queryData;

  useEffect(() => {
    return () => setUserLocation(null);
  }, [setUserLocation]);

  const stopsList = data?.map(stop => {
    return <StopListItem {...stop} key={stop.id} isLink/>;
  });

  if (isError) {
    return <Text>{t('error')}</Text>;
  }

  const getCurrentPosition = (pos) => {
    const coords = pos.coords;
    setUserLocation({lat: coords.latitude, lng: coords.longitude});
  }

  const denyCurrentPosition = (err) => {
    setUserLocation({error: true, code: err.code});
  }

  return (
    <SimpleGrid w='100%' overflowY='scroll' maxH='100vh' spacing={5}>
      <SimpleGrid w='100%' spacing={5}>
        <MenuGroup
          p='0 1rem'
          icon={IoLocationOutline}
          text={t('stops')}
        />
        <SearchQueryInput
          handleClearInput={handleClearInput}
          handleChange={handleChange}
          value={queryDraft}
          placeholder={t('stop.search.placeholder')}
        />
        <Flex direction='column'>
          <MenuGroup
            cursor={userLocation?.error ? 'initial' : 'pointer'}
            color={userLocation?.error ? 'gray.500' : 'initial'}
            w='100%'
            icon={MdMyLocation}
            text={t('stops.findNearest')}
            p='1rem'
            _hover={userLocation?.error ? null : {
              bgColor: 'gray.50',
            }}
            onClick={userLocation?.error ? null : () => navigator.geolocation.getCurrentPosition(getCurrentPosition, denyCurrentPosition)}
          >
            {
              userLocation?.code ?
              <Text fontSize='14px' lineHeight='2rem'>{t('stops.geolocationError')}</Text>
              : null
            }
          </MenuGroup>
        </Flex>
      </SimpleGrid>
      <Flex direction='column'>
        {isLoading ? <Loader size='lg' /> : stopsList}
      </Flex>
    </SimpleGrid>
  );
}
