import { Routes, Route } from 'react-router-dom';

import { HomeNavigation } from './HomeNavigation';
import { BusRoutes } from './BusRoutes';
import { BusRoute } from './BusRoute';
import { DirectionStop } from './DirectionStop';
import { Breadcrumbs } from './Breadcrumbs';
import { Stops } from './Stops';
import { Stop } from './Stop';

import { useRoutesQuery } from 'services/api/queries';

import config from '../config/config';

export function Home() {
  const routes = useRoutesQuery();
  
  return (
    <>
      {config.logo}
      <Breadcrumbs />
      <Routes>
        <Route index element={<HomeNavigation />} />
        <Route path='stops' element={<Stops />} />
        <Route path='stops/:stopId' element={<Stop routes={routes} />} />
        <Route path='routes' element={<BusRoutes routes={routes} />} />
        <Route path='routes/:routeId' element={<BusRoute />} />
        <Route path='routes/:routeId/direction/:directionId/stop/:stopId/*' element={<DirectionStop />} />
      </Routes>
    </>
  );
}