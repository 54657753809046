import { useEffect } from "react";
import { useLocation } from "react-router-dom"

import { useSelectedStore } from 'store/useSelectedStore';
import { stores } from 'store/stores';
import * as selectors from 'store/selectors';

export const useClearRouteDirection = () => {
  const location = useLocation();

  const { clearRouteDirection } = useSelectedStore(stores.route, [
    selectors.clearRouteDirection,
  ]);

  useEffect(() => {
    const routesLocation = location.pathname.match(/routes\/\d+/);

    if (!routesLocation) {
      clearRouteDirection()
    }
  }, [location.pathname, clearRouteDirection]);

}